export const COLORS = {
    brand: '#7399C6',
    globalHeader: '#11294D',
    schemePrimary:'#186ADE',
    schemeSecondary:'#F2F5F7',
    schemeInfo:'#0D1826',
    surfacePrimary:'#FFFFFF',
    surfaceSecondary:'#F2F5F7',
    surfaceTertiary:'#DCE3E8',
    surfaceModerate:'#C1CCD6',
    
  };