import { Outlet, Link } from "react-router-dom";
import * as Constants from "../constants";



function NavContainer(){
  const Style = {
    display: "flex",
    flexDirection: "row",
    position: 'absolute',
    top:0,
    right:0,
    backgroundColor: Constants.COLORS.globalHeader,
    color: Constants.COLORS.schemeSecondary,
    padding: "0px 16px",
    borderRadius: "5px 20px", // This will make the corners round

  }
  const navItems = [
    'About',
    'Project',
    'Blog',
    'Contact'
  ]

  const navItemStyle = {
    padding: "16px 24px",
    fontWeight: "bold",
  }
  return(
    <div className="NavContainer" style={Style}>
      {navItems.map((item, index) => (
        <div key={index} style={navItemStyle}>
          <Link to={`/${item.toLowerCase()}`}>{item}</Link>
        </div>
      ))}
    </div>
  )
}

function MainPanel(){
  const style = {
    display: "flex",
    flexDirection: "column",
    alignItems:"flex-start",
    minWidth: "700px",
    maxWidth: "1000px",
    margin: "0px 16px",
    borderRadius: "20px", // This will make the corners round
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // This will give the div an elevation
    padding: "8px 32px",
    position: 'relative',
    backgroundColor: Constants.COLORS.surfacePrimary,
  }
  return(
    <div className="MainPanel" style={style}>
      <NavContainer/>
      <Outlet />
    </div>
  )
}

function SideBar(){
  const SideBarStyle = {
    display: "flex",
    flexDirection: "column",
    // width: "100%",
    margin: "0px 16px",
    borderRadius: "20px", // This will make the corners round
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // This will give the div an elevation
    padding: "16px",
    backgroundColor: Constants.COLORS.surfacePrimary,
    maxHeight: "550px",
  }

  const imgStyle = {
    width: "100px",
    borderRadius: "50%",
  }

  const SideBarContentStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "16px 0px",
  }

  const SideBarContentItemStyle = {
    display: "flex",
    flexDirection: "row",
    margin: "10px 0px",
  }

  const ContactDetailRightStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    textAlign: "left",
  }

  const SeparatorStyle = {
    border: "1px solid #E0E0E0",
  }

  const SideBarContentItems = [
    {
      'icon':'mail',
      'title':'EMAIL',
      'content':'wlywylm@gmail.com',
    },
    {
      'icon':'call',
      'title':'PHONE',
      'content':'+852 9858-2986',
    },
    {
      'icon':'calendar_month',
      'title':'BIRTHDAY',
      'content':'April 14, 1993',
    },
    {
      'icon':'home',
      'title':'LOCATION',
      'content':'Hong Kong, China',
    },
  ]

  return(
    <div className="SideBar" style={SideBarStyle}>
      
      <div className="SideBarHeader" style={{display:'flex', flexDirection:'column', alignItems: "center", margin:'16px 0'}}>
        <img src="https://media.licdn.com/dms/image/C4E03AQGLp8LVlaTOJA/profile-displayphoto-shrink_100_100/0/1517238911991?e=1714608000&v=beta&t=a5O_OuX80VaUGch5Y5PJNyaMb4fhXlrBBjTwewVuiT0" alt="Avatar" style={imgStyle}/>
        
        <h1 class="name" style={{padding:'16px 0', fontSize:'150%', fontWeight:'bold'}}>Leo Wu</h1>
        {/* <div class="title" >
          Executive Director
        </div> */}
      </div>

      <div className='Separator' style={SeparatorStyle}></div>

      <div className="SideBarContent" style={SideBarContentStyle}>
        {SideBarContentItems.map((item, index) => (
          <div key={index} style={SideBarContentItemStyle}>
            <div style={{margin: 'auto 8px'}}>
              <span class="material-symbols-outlined" style={{fontSize: '200%'}}>
              {item.icon}
              </span>
            </div>
            <div class="ContactDetailRight" style={ContactDetailRightStyle}>
              <div class="title" style={{fontWeight: 'bold'}}>{item.title}</div>
              <div class="content">{item.content}</div>
            </div>
          </div>
        ))}
      </div>

      
    </div>
  )
}
function Root() {
  const wrapperStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "32px",

  }

  const style = {
    display: "flex",
    flexDirection: "row",
    fontFamily: "'Poppins', sans-serif",
  }

  return (
      <div class="AppWrapper" style={wrapperStyle}>
        <div className="App" style={style}>
          <SideBar/>
          <MainPanel/>
        </div>
      </div>
  );
}

export default Root;
