
function MainPanelHeader(){
    const style = {
      fontSize: '250%',
      fontWeight: 'bold',
    }
    return(
      <div className="MainPanelHeader" style={style}>
        <h1>Resume</h1>
      </div>
    )
  }
  
  function MainPanelContent(){
    return(
      <div className="MainPanelContent">
        <span>Main Panel Content Placeholder</span>
      </div>
    )
  }

  
export default function Resume(){
    return(
      <div>
        <MainPanelHeader/>
        <MainPanelContent/>
      </div>

    )  
}
