
function MainPanelHeader(){
    const style = {
      fontSize: '250%',
      fontWeight: 'bold',
    }
    return(
      <div className="MainPanelHeader" style={style}>
        <h1>About Me</h1>
      </div>
    )
  }
  
  function MainPanelContent(){
    return(
      <div className="MainPanelContent">
        <p>I'm full stake developer from Hongkong, China, working in financial industry. I enjoy solving complex challenges in a innovative and collaborative way.</p>
        <p>My job is to lead the sofeware development team building the risk calculation engine and infrastructure for financial industry users. Moreover, I provide financial data analysis to front desk and make sure that is timely and high quality.
              My aim is to provide the best solution to the business and make sure the system is stable and reliable. I have experience in many high concurrency and high performance system development and maintenance. </p>
      </div>

    )
  }

  
export default function About(){
  const headerStyle = {
    fontSize: '250%',
    fontWeight: 'bold',
  }

  const subHeaderStyle = {
    fontSize: '150%',
    fontWeight: 'bold',
    padding: '16px 0',
  }

  const flexContainerStyle = {
    display: "flex",
    flexDirection: 'row',
    flexWrap: 'wrap',
  }

  const introItems = [
    {icon: '🧭', title: 'Engineering Team Lead', content: 'Define product strategy and manage delivery'},
    {icon: '🚀', title: 'Dev. Efficiency Management', content: 'Improve development stake and focus on developer experience'},
    {icon: '⛑️', title: 'Production Env. Management', content: 'Renew and maintain production environment and make sure it is stable and reliable'},
    {icon: '👨‍💻', title: 'Full Stake Developer', content: 'Experienced in large sale system development and maintenance'},

  ]

  const careerItems = [
    {icon: 'https://upload.wikimedia.org/wikipedia/commons/3/34/Morgan_Stanley_Logo_1.svg', timePeriod: '2015 - 2017', title: 'Software Engineer, Morgan Stanley.', content: 'I was one of the Developer in the FX Core Sales and Trading team and owned FX Rating Service for Real Time Risk Management.'},
    {icon: 'https://upload.wikimedia.org/wikipedia/commons/6/61/Goldman_Sachs.svg', timePeriod: '2017 - Now', title: 'Engineering Team Lead, Goldman Sachs.', content: 'Manage the trading booking platform to help traders effectively and accurately calculate the position risk.'},

  ]
  return(
    <div>
      <h1 style={headerStyle}>About Me</h1>
      <div style={{padding: '16px 0px'}}>
        <p style={{padding: '16px 0px'}}>I'm full stake developer from Hongkong, China, working in financial industry. I enjoy solving complex challenges in a innovative and collaborative way.</p>
        <p>My job is to lead the sofeware development team building the risk calculation engine and infrastructure for financial industry users. Moreover, I provide financial data analysis to front desk and make sure that is timely and high quality.
              My aim is to provide the best solution to the business and make sure the system is stable and reliable. I have experience in high concurrency and high performance system development and maintenance. </p>

      </div>
    
      <h2 style={subHeaderStyle}>What I'm Doing</h2>
      <div style={flexContainerStyle}>
        {introItems.map((item, index) => (
          <div style={{flex: '50%', display: 'flex', flexDirection:'row', padding: '16px 0'}}>
            <div style={{margin: 'auto 8px'}}>
              <span class="material-symbols-outlined" style={{fontSize: '200%'}}>
              {item.icon}
              </span>
            </div>
            <div class="ContactDetailRight" style={{padding: '0 8px'}}>
              <div class="title" style={{fontWeight: 'bold'}}>{item.title}</div>
              <div class="content">{item.content}</div>
            </div>
          </div>
        ))}
      </div>

      <h2 style={subHeaderStyle}>Career</h2>
      <div style={{margin: '16px 0px'}}>
      {careerItems.map((item, index) => (
        <div style={{ display: 'flex', flexDirection:'column', margin: '8px 0'}}>
          <div style={{fontWeight: 'bold', fontSize: '100%', margin:'8px 0'}}>{item.timePeriod}</div>
          <div style={{flex: '50%', display: 'flex', flexDirection:'row'}}>
            <div style={{margin: 'auto 8px'}}>
              <img src={item.icon} alt="Logo" style={{width: '50px'}}/>
            </div>
            <div class="ContactDetailRight" style={{padding: '0 8px'}}>
              <div class="title" style={{fontWeight: 'bold'}}>{item.title}</div>
              <div class="content">{item.content}</div>
            </div>
          </div>
        </div>
      ))}
      </div>
    </div>

  )  
}
